// Generated by Framer (b12dec4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["YPxbpDomp"];

const variantClassNames = {YPxbpDomp: "framer-v-pproap"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, IAOb4UvPp: image ?? props.IAOb4UvPp ?? {src: new URL("assets/dxw4snMwHYmIaXPmYiqevvyw8.png", import.meta.url).href}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, IAOb4UvPp, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "YPxbpDomp", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-T6AZs", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 469, intrinsicWidth: 268, pixelHeight: 469, pixelWidth: 268, sizes: "min(400px, 100vw)", ...toResponsiveImage(IAOb4UvPp)}} className={cx("framer-pproap", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"YPxbpDomp"} ref={ref} style={{...style}}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-T6AZs [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-T6AZs .framer-1wvjb0u { display: block; }", ".framer-T6AZs .framer-pproap { height: 454px; position: relative; width: 400px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 454
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"IAOb4UvPp":"image"}
 * @framerImmutableVariables false
 */
const FramerZSCYS_1il: React.ComponentType<Props> = withCSS(Component, css, "framer-T6AZs") as typeof Component;
export default FramerZSCYS_1il;

FramerZSCYS_1il.displayName = "bulb off";

FramerZSCYS_1il.defaultProps = {height: 454, width: 400};

addPropertyControls(FramerZSCYS_1il, {IAOb4UvPp: {__defaultAssetReference: "data:framer/asset-reference,dxw4snMwHYmIaXPmYiqevvyw8.png?originalFilename=27878847-removebg-preview.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerZSCYS_1il, [])